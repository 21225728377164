export default defineNuxtRouteMiddleware(async () => {
  const { $pinia, $i18n, runWithContext } = useNuxtApp();

  const checkoutStore = useCheckoutStore($pinia);
  const discountStore = useDiscountStore($pinia);
  const onboardingStore = useOnboardingStore($pinia);
  const userStore = useUserStore($pinia);

  const segment = await runWithContext(() => discountStore.getActiveSegment());
  const user = userStore.user;

  if (user) {
    await runWithContext(() =>
      checkoutStore.getAvailableSubscriptionPlans({
        locale: $i18n.locale.value,
        segment,
        country: user.country,
        user_token: user.user_token,
      }),
    );
  } else {
    await runWithContext(() =>
      checkoutStore.getAvailableSubscriptionPlans({
        locale: $i18n.locale.value,
        segment,
        country: onboardingStore.registrationParams.country,
      }),
    );
  }
});
